import Afisha from "shared/assets/images/menu/Afisha.jpg";
import Concept from "shared/assets/images/menu/Concept.jpg";
import Coctail from "shared/assets/images/menu/Coctail.jpg";
import Kitchen from "shared/assets/images/menu/Kitchen.jpg";
import Interior from "shared/assets/images/menu/Interier.jpg";
import Contacts from "shared/assets/images/menu/Contacts.jpg";
import Photo from "shared/assets/images/menu/Photo.jpg";

export const menuList = [
  {
    title: "Афиша",
    img: Afisha,
    slideTo: 1,
    scroll: "#afisha",
  },
  {
    title: "Концепция",
    img: Concept,
    slideTo: 3,
    scroll: "#concept",
  },

  {
    title: "Коктейльная карта",
    img: Coctail,
    slideTo: 6,
    scroll: "#coctail",
  },
  {
    title: "кухня",
    img: Kitchen,
    slideTo: 8,
    scroll: "#kitchen",
  },
  {
    title: "Интерьер",
    img: Interior,
    // slideTo: 9,
    slideTo: 10,
    scroll: "#interior",
  },
  {
    title: "Фотоотчеты",
    img: Photo,
    // slideTo: 9,
    slideTo: 12,
    scroll: "#photo",
  },
  {
    title: "Контакты",
    img: Contacts,
    slideTo: 14,
    scroll: "#contacts",
  },
];
