import { Button, PlusIcon } from "shared/ui";
import s from "./styles.module.sass";
import coctail from "../../images/coctail1.jpg";
import { observer } from "mobx-react-lite";
import { useModalStore } from "entities/Modal";
import { CoctailSubText } from "../CoctailSubText";
import { useCarouselStore } from "entities/Carousel";
import { useGetAll } from "shared/lib";
import { API_URL } from "shared/config";

export const CoctailSub = observer(() => {
  const modal = useModalStore();
  const carousel = useCarouselStore();

  // const sectionClass = `${s.section} ${
  //   carousel.activeIndex >= 5 ? s.active : ""
  // }`;
  const sectionClass = `${s.section} ${
    carousel.activeIndex >= 7 ? s.active : ""
  }`;
  const { resData } = useGetAll();

  return (
    <section id="coctail" className={sectionClass}>
      <div className={s.content}>
        <div className={s.top}>
          <p className={s.title}>
            <span className={s.wordWrapper}>
              <span className={s.word}>Разнообразие вкусов </span>
            </span>
            <span className={s.wordWrapper}>
              <span className={s.word}>и впечатлений</span>
            </span>
          </p>
          {/* <span className={s.title}>Разнообразие вкусов и впечатлений</span> */}
          <CoctailSubText />
        </div>
        <div className={s.bottom}>
          <span className={s.title}>
            <span className={s.word}>коктейльная карта</span>
          </span>
          <Button
            link={resData && `${API_URL}${resData["Загрузка pdf Барное меню"]}`}
            variable="secondary"
            className={s.btn}
          >
            Барное меню
          </Button>
        </div>
      </div>
      <div className={s.coctail}>
        <img
          onClick={() => modal.handleOpenCoctailModal()}
          src={coctail}
          alt="coctail"
        />
        <Button
          onClick={() => modal.handleOpenCoctailModal()}
          type="rounded"
          variable="primary"
        >
          <PlusIcon />
        </Button>
      </div>
    </section>
  );
});
