import { makeAutoObservable } from "mobx";

class PhotoStore {
  currentPhotoIndex: number = 0;
  menuIsOpen = false;

  constructor() {
    makeAutoObservable(this);
  }
  setCurrentIndex(index: number) {
    this.currentPhotoIndex = index;
  }
  handleOpenMenu() {
    this.menuIsOpen = !this.menuIsOpen;
  }
}

export default PhotoStore;
