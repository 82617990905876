import { Button, PlusIcon } from "shared/ui";
import s from "./styles.module.sass";
import food from "../../images/food1.jpg";
import { observer } from "mobx-react-lite";
import { useModalStore } from "entities/Modal";
import { FoodSubText } from "../FoodSubText";
import { useCarouselStore } from "entities/Carousel";
import { useGetAll } from "shared/lib";
import { API_URL } from "shared/config";

export const FoodSub = observer(() => {
  const modal = useModalStore();
  const carousel = useCarouselStore();

  // const sectionClass = `${s.section} ${
  //   carousel.activeIndex >= 7 ? s.active : ""
  // }`;
  const sectionClass = `${s.section} ${
    carousel.activeIndex >= 9 ? s.active : ""
  }`;
  const { resData } = useGetAll();

  return (
    <section id="kitchen" className={sectionClass}>
      <div className={s.content}>
        <div className={s.top}>
          <span className={s.title}>
            <span className={s.word}>Богатый выбор блюд</span>
          </span>
          <FoodSubText />
        </div>
        <div className={s.bottom}>
          <span className={s.title}>
            <span className={s.word}>Кухня</span>
          </span>
          <div className={s.btns}>
            <Button
              link={
                resData && `${API_URL}${resData["Загрузка pdf Основное меню"]}`
              }
              className={s.btn}
              variable="secondaryInvarion"
            >
              Меню
            </Button>
            <Button
              link={
                resData && `${API_URL}${resData["Загрузка pdf Crazy меню"]}`
              }
              className={s.btn}
              variable="secondaryInvarion"
            >
              Crazy меню
            </Button>
          </div>
        </div>
      </div>
      <div className={s.food}>
        <img
          onClick={() => modal.handleOpenFoodModal()}
          src={food}
          alt="food"
        />
        <Button
          onClick={() => modal.handleOpenFoodModal()}
          type="rounded"
          variable="primary"
        >
          <PlusIcon />
        </Button>
      </div>
    </section>
  );
});
