import { useContext } from "react";

import { PhotoStoreContext } from "./context";

export const usePhotoStore = () => {
  const store = useContext(PhotoStoreContext);
  if (!store) {
    throw new Error("Photo Store has not been installed");
  }

  return store;
};
