import { useEffect, useState } from "react";
import { placeApi } from "shared/api";

export const useGetAll = () => {
  const [resData, setResData] = useState<any>(null);

  const res = async () => {
    try {
      const { data } = await placeApi.getAll();
      setResData(data.CONTENT);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    res();
  }, []);

  return { resData };
};
