import { FC } from "react";
import s from "./styles.module.sass";

interface ILogoIconProps {
  isDark: boolean;
}

export const LogoIcon: FC<ILogoIconProps> = ({ isDark }) => {
  const logoColorClass = isDark ? s.dark : s.light;

  return (
    <svg
      width="104"
      height="28"
      viewBox="0 0 104 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={s.svg}
    >
      <path
        d="M10.3567 10.3596C10.1429 10.8933 9.81001 11.36 9.35689 11.76C8.90337 12.1598 8.32372 12.4796 7.61752 12.7198C6.91092 12.9596 6.06442 13.0801 5.07841 13.0801H4.79841V4.39966H4.91847C5.93119 4.39966 6.80422 4.51369 7.53755 4.73951C8.27047 4.96696 8.87017 5.27987 9.33705 5.67988C9.80292 6.07988 10.1429 6.53964 10.3567 7.05995C10.5697 7.57986 10.6766 8.13359 10.6766 8.71972C10.6766 9.27973 10.5697 9.82698 10.3567 10.3596ZM12.8958 2.67953C12.0823 1.87972 11.0566 1.23323 9.81689 0.739859C8.5772 0.246485 7.1308 0 5.47808 0H0V28H4.79841V17.6801C6.55802 17.6801 8.09736 17.4198 9.41682 16.8999C10.7365 16.38 11.8363 15.6867 12.7158 14.8197C13.5955 13.9536 14.2553 12.9736 14.6951 11.8797C15.1348 10.7868 15.3549 9.67973 15.3549 8.55992C15.3549 7.49358 15.1482 6.44668 14.7352 5.41942C14.3215 4.39338 13.7087 3.47995 12.8958 2.67953Z"
        fill="#DCC5B7"
        className={logoColorClass}
      />
      <path
        d="M21.2154 23.3601V0H16.4082V28H33.1932L31.9914 23.3601H21.2154Z"
        fill="#DCC5B7"
        className={logoColorClass}
      />
      <path
        d="M43.0447 18.6798L45.9892 11.2799L48.9332 18.6798H43.0447ZM45.4279 0L37.9685 18.6798H34.6074V23.1601H36.1796L34.2471 28H39.3364L41.262 23.1601H50.7159L52.6415 28H57.731L46.55 0H45.4279Z"
        fill="#DCC5B7"
        className={logoColorClass}
      />
      <path
        d="M78.7174 1.23334C76.927 0.410849 74.9739 0 72.8572 0C70.8975 0 69.0678 0.365839 67.3695 1.09633C65.6706 1.82761 64.1841 2.82617 62.9101 4.09223C61.6358 5.35908 60.6293 6.84008 59.892 8.53682C59.1541 10.2343 58.7852 12.0616 58.7852 14.0196C58.7852 15.9777 59.1541 17.8055 59.892 19.5022C60.6293 21.1998 61.6358 22.6744 62.9101 23.9274C64.1841 25.1804 65.6706 26.1728 67.3695 26.9039C69.0678 27.6346 70.8975 28 72.8572 28V23.3791C71.5501 23.3791 70.3289 23.1313 69.1921 22.635C68.0555 22.1396 67.0689 21.4734 66.2329 20.6376C65.3964 19.8025 64.7299 18.8168 64.2337 17.681C63.7372 16.5456 63.4891 15.3251 63.4891 14.0196C63.4891 12.7409 63.7372 11.5268 64.2337 10.3775C64.7299 9.22923 65.3964 8.23721 66.2329 7.40103C67.0689 6.56605 68.0555 5.9002 69.1921 5.40409C70.3289 4.90838 71.5501 4.65992 72.8572 4.65992C74.2681 4.65992 75.5814 4.94764 76.7964 5.52128C78.0117 6.09591 79.0506 6.87954 79.9128 7.87117L83.3229 4.62086C82.0426 3.18527 80.507 2.05583 78.7174 1.23334Z"
        fill="#DCC5B7"
        className={logoColorClass}
      />
      <path
        d="M102.222 23.3601H89.1675V15.7197H96.713V11.1196H89.1675V4.47966H97.1519V0H84.377V28H103.42L102.222 23.3601Z"
        fill="#DCC5B7"
        className={logoColorClass}
      />
    </svg>
  );
};
