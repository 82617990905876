import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, EffectFade } from "swiper/modules";

import { useMenuStore } from "entities/Menu";
import { photoList } from "pages/Main/config/photo";

import s from "./styles.module.sass";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/css";
import "swiper/css/effect-creative";

import { Button, TelegramIcon, VkIcon, WhatsAppIcon } from "shared/ui";

import { useCarouselStore } from "entities/Carousel";
import { usePhotoStore } from "entities/Photo";
import { Accordion } from "shared/ui";
import { useGetAll } from "shared/lib";
import { API_URL } from "shared/config";

export const PhotoSubMob = observer(() => {
  const [selectedAccordion, setSelectedAccordion] = useState<number>(0);
  const photo = usePhotoStore();
  const menuClass = `${s.photo} `;

  useEffect(() => {
    photo.setCurrentIndex(selectedAccordion);
  }, [selectedAccordion]);

  const { resData } = useGetAll();

  return (
    <div id="photo" className={menuClass}>
      <div className={s.menuList}>
        {resData
          ? resData["Фотоотчеты"].map((menu, index) => (
              <Accordion
                isShow={selectedAccordion === index}
                onClick={() => setSelectedAccordion(index)}
                title={menu.NAME}
                key={index}
              >
                <span className={s.date}>{menu.DATE}</span>
                <div className={s.imageWrapper}>
                  <img src={`${API_URL}${menu.PREVIEW_PICTURE}`} alt="" />
                </div>
                <Button
                  onClick={() => {
                    photo.handleOpenMenu();
                  }}
                  variable="secondary"
                  className={s.photoBtn}
                >
                  Смотреть фотоотчет
                </Button>
              </Accordion>
            ))
          : null}
      </div>
    </div>
  );
});
