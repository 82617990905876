import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useSwiper, useSwiperSlide } from "swiper/react";
import s from "./styles.module.sass";
import { useGetAll, useWindowSize } from "shared/lib";
import { API_URL } from "shared/config";
export const Video = () => {
  const [playing, setPlaying] = useState(false);
  const [isEnd, setEnd] = useState(false);
  const [muted, setMuted] = useState(true);
  const [enabled, setEnabled] = useState(true);
  const swiper = useSwiper();
  const slide = useSwiperSlide();
  const loader = document.querySelector(".loader");

  let observer = new MutationObserver((mutationRecords) => {
    mutationRecords.forEach(function (mutation) {
      if (loader?.className.includes("loaded")) {
        setTimeout(() => {
          setPlaying(true);
        }, 50);
      }
    });
  });
  //@ts-ignore
  observer.observe(loader, {
    attributes: true,
    attributeOldValue: true,
  });
  useEffect(() => {
    if (!slide.isActive) {
      return;
    }
    if (isEnd) {
      swiper.slideNext();
    }
  }, [isEnd, swiper]);
  useEffect(() => {
    if (isEnd && slide.isActive) {
      setEnabled(true);
      setEnd(false);
    }
  }, [slide, slide.isActive]);

  const { resData } = useGetAll();

  return (
    <div className={s.videoWrapper}>
      {enabled && resData ? (
        <ReactPlayer
          url={`${API_URL}${resData["Видео для десктопа"]}`}
          playing={playing}
          muted={muted}
          width="100%"
          height="100%"
          onEnded={() => {
            setEnd(true);
            setTimeout(() => {
              setEnabled(false);
            }, 1000);
          }}
        />
      ) : null}
    </div>
  );
};
