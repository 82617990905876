import { useScroll } from "shared/lib";

import bg from "../../images/photo-bg.jpg";

import { Section } from "entities/Section";

export const Photo = () => {
  const [titleRef] = useScroll();

  return <Section titleRef={titleRef} bg={bg} title="фотоотчеты" />;
};
