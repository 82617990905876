import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { EffectCreative, EffectFade } from "swiper/modules";

import s from "./styles.module.sass";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/css";
import "swiper/css/effect-creative";

import { Button } from "shared/ui";

import { usePhotoStore } from "entities/Photo";
import { useGetAll } from "shared/lib";
import { API_URL } from "shared/config";

export const PhotoSub = observer(() => {
  const [swiper, setSwiper] = useState(null);
  const [swiperText, setSwiperText] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const photo = usePhotoStore();
  const menuClass = `${s.photo} `;
  const swiperParent = useSwiper();
  useEffect(() => {
    if (swiper && swiperText) {
      //@ts-ignore
      swiper.slideTo(activeIndex);
      //@ts-ignore
      swiperText.slideTo(activeIndex);
    }
  }, [activeIndex, swiper, swiperText]);
  const { resData } = useGetAll();

  return (
    <div className={menuClass}>
      <div className={s.leftPart}>
        <Swiper
          //@ts-ignore
          onSwiper={(swiper) => setSwiperText(swiper)}
          grabCursor={true}
          effect={"fade"}
          speed={600}
          creativeEffect={{
            prev: {
              translate: [0, "0%", -1],
            },
            next: {
              translate: [0, "-100%", 1],
            },
          }}
          modules={[EffectFade]}
          className={`${s.swiperText} swiperPhotoText`}
        >
          {resData
            ? resData["Фотоотчеты"].map((menu, index) => (
                <SwiperSlide key={index} className={s.swiperItem}>
                  <span>{menu.DATE}</span>
                  <p>{menu.NAME}</p>
                </SwiperSlide>
              ))
            : null}
        </Swiper>
        <Swiper
          //@ts-ignore
          onSwiper={(swiper) => setSwiper(swiper)}
          grabCursor={true}
          effect={"creative"}
          speed={600}
          creativeEffect={{
            prev: {
              translate: [0, "0%", -1],
            },
            next: {
              translate: [0, "-100%", 1],
            },
          }}
          modules={[EffectCreative]}
          className={s.swiper}
        >
          {resData
            ? resData["Фотоотчеты"].map((menu, index) => (
                <SwiperSlide key={index} className={s.swiperItem}>
                  <img src={`${API_URL}${menu.PREVIEW_PICTURE}`} alt="menu" />
                </SwiperSlide>
              ))
            : null}
        </Swiper>
        <Button
          onClick={() => {
            photo.handleOpenMenu();
          }}
          variable="secondary"
          className={s.photoBtn}
        >
          Смотреть фотоотчет
        </Button>
      </div>
      <div className={s.rightPart}>
        <div
          className={s.menuList}
          onMouseLeave={() => {
            swiperParent.mousewheel.enable();
          }}
          onMouseEnter={() => swiperParent.mousewheel.disable()}
        >
          {resData
            ? resData["Фотоотчеты"].map((menu, index) => (
                <span
                  className={`${index === activeIndex ? s.active : ""}`}
                  onClick={() => {
                    photo.setCurrentIndex(index);
                    setActiveIndex(index);
                  }}
                  key={index}
                >
                  {menu.NAME}
                </span>
              ))
            : null}
        </div>
      </div>
    </div>
  );
});
