import { observer } from "mobx-react-lite";
import { useRef } from "react";
import s from "./styles.module.sass";
import "swiper/css";
import "swiper/css/navigation";
import { photoList } from "pages/Main/config/photo";
import { usePhotoStore } from "entities/Photo";
import { Button, Fancybox } from "shared/ui";
import { useEffect, useState } from "react";
import { useGetAll } from "shared/lib";
import { API_URL } from "shared/config";

export const PhotoModal = observer(() => {
  const [showModal, setShowModal] = useState(false);
  const [isActiveBtn, setActiveBtn] = useState(false);

  const modalContentRef = useRef(null);
  const photo = usePhotoStore();

  const modalClass = `${s.modalWrapper} ${showModal ? s.open : ""}`;

  useEffect(() => {
    setShowModal(photo.menuIsOpen);
  }, [photo.menuIsOpen]);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => {
      photo.handleOpenMenu();
    }, 300);
  };
  const handleTop = () => {
    if (modalContentRef.current) {
      //@ts-ignore
      modalContentRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    const currentRef = modalContentRef.current;
    if (currentRef) {
      const handleScroll = () => {
        //@ts-ignore
        if (currentRef.scrollTop > 150) {
          setActiveBtn(true);
        } else {
          setActiveBtn(false);
        }
      };
      //@ts-ignore
      currentRef.addEventListener("scroll", handleScroll);

      // Очистка слушателя при размонтировании компонента
      return () => {
        //@ts-ignore
        currentRef.removeEventListener("scroll", handleScroll);
      };
    }
  }, [modalContentRef.current]);

  const { resData } = useGetAll();

  if (!photo.menuIsOpen) {
    return null;
  }

  return (
    <div className={modalClass}>
      <div className={s.bg} />
      <Button
        className={`${s.toTop} ${isActiveBtn ? s.active : ""}`}
        variable="primaryInvarion"
        onClick={handleTop}
      >
        <svg
          width="18"
          height="15"
          viewBox="0 0 18 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.2186 6.91769L17.9416 6.91769L17.9416 8.08221L2.2186 8.08221L7.5556 13.6978L6.71149 14.5L0.0586678 7.49995L6.71149 0.499877L7.5556 1.30212L2.2186 6.91769Z"
            fill="#DCC5B7"
          />
        </svg>
      </Button>
      <div className={s.contentWrapper} ref={modalContentRef}>
        <div className={s.content}>
          <div className={s.top}>
            <Button
              variable="primaryInvarion"
              onClick={handleClose}
              className={s.backBtn}
            >
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.2186 6.91769L17.9416 6.91769L17.9416 8.08221L2.2186 8.08221L7.5556 13.6978L6.71149 14.5L0.0586678 7.49995L6.71149 0.499877L7.5556 1.30212L2.2186 6.91769Z"
                  fill="#DCC5B7"
                />
              </svg>
            </Button>
            <div className={s.info}>
              <span>
                {resData
                  ? resData["Фотоотчеты"][photo.currentPhotoIndex].DATE
                  : null}
              </span>
              <p>
                {resData
                  ? resData["Фотоотчеты"][photo.currentPhotoIndex].NAME
                  : null}
              </p>
            </div>
          </div>
          <Fancybox>
            <div className={s.photoList}>
              {resData
                ? resData["Фотоотчеты"][photo.currentPhotoIndex].PICTURE.map(
                    (photo) => (
                      <div
                        data-caption={`${API_URL}${photo}`}
                        className={s.imageWrapper}
                        key={photo}
                      >
                        <img
                          data-fancybox="gallery"
                          src={`${API_URL}${photo}`}
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    )
                  )
                : null}
            </div>
          </Fancybox>
        </div>
      </div>
    </div>
  );
});
