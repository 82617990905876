import { FC, PropsWithChildren } from "react";

import { PhotoStoreContext } from "./context";
import PhotoStore from "./store";

export const PhotoProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <PhotoStoreContext.Provider value={new PhotoStore()}>
      {children}
    </PhotoStoreContext.Provider>
  );
};
