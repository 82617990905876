import int1 from "shared/assets/images/interior/int1.jpg";
import int2 from "shared/assets/images/interior/int2.jpg";
import int3 from "shared/assets/images/interior/int3.jpg";
import int4 from "shared/assets/images/interior/int4.jpg";
import int5 from "shared/assets/images/interior/int5.jpg";
import int6 from "shared/assets/images/interior/int6.jpg";
import int7 from "shared/assets/images/interior/int7.jpg";
import int8 from "shared/assets/images/interior/int8.jpg";
import int9 from "shared/assets/images/interior/int9.jpg";

export const interiorList = [
  {
    id: 1,
    img: int1,
  },
  {
    id: 2,
    img: int2,
  },
  {
    id: 3,
    img: int3,
  },
  {
    id: 4,
    img: int4,
  },
  {
    id: 5,
    img: int5,
  },
  {
    id: 6,
    img: int6,
  },
  {
    id: 7,
    img: int7,
  },
  {
    id: 8,
    img: int8,
  },
  {
    id: 9,
    img: int9,
  },
];
