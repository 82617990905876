export const WhatsAppIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4642 4.49959C16.6238 3.65068 15.6228 2.97761 14.5195 2.51963C13.4163 2.06165 12.2329 1.82792 11.0384 1.83209C6.03336 1.83209 1.95419 5.91125 1.95419 10.9163C1.95419 12.5204 2.37586 14.0788 3.16419 15.4538L1.88086 20.1654L6.69336 18.9004C8.02253 19.6246 9.51669 20.0096 11.0384 20.0096C16.0434 20.0096 20.1225 15.9304 20.1225 10.9254C20.1225 8.49625 19.1784 6.21375 17.4642 4.49959ZM11.0384 18.4696C9.68169 18.4696 8.35253 18.1029 7.18836 17.4154L6.91336 17.2504L4.05336 18.0021L4.81419 15.2154L4.63086 14.9313C3.87713 13.7276 3.4769 12.3364 3.47586 10.9163C3.47586 6.75459 6.86753 3.36292 11.0292 3.36292C13.0459 3.36292 14.9434 4.15125 16.3642 5.58125C17.0677 6.28155 17.6253 7.11453 18.0045 8.03189C18.3837 8.94926 18.5771 9.93277 18.5734 10.9254C18.5917 15.0871 15.2 18.4696 11.0384 18.4696ZM15.1817 12.8229C14.9525 12.7129 13.8342 12.1629 13.6325 12.0804C13.4217 12.0071 13.275 11.9704 13.1192 12.1904C12.9634 12.4196 12.5325 12.9329 12.4042 13.0796C12.2759 13.2354 12.1384 13.2538 11.9092 13.1346C11.68 13.0246 10.9467 12.7771 10.085 12.0071C9.40669 11.4021 8.95753 10.6596 8.82003 10.4304C8.69169 10.2013 8.80169 10.0821 8.92086 9.96292C9.02169 9.86209 9.15003 9.69709 9.26003 9.56875C9.37003 9.44042 9.41586 9.33959 9.48919 9.19292C9.56253 9.03709 9.52586 8.90875 9.47086 8.79875C9.41586 8.68875 8.95753 7.57042 8.77419 7.11209C8.59086 6.67209 8.39836 6.72709 8.26086 6.71792H7.82086C7.66503 6.71792 7.42669 6.77292 7.21586 7.00209C7.01419 7.23125 6.42753 7.78125 6.42753 8.89959C6.42753 10.0179 7.24336 11.0996 7.35336 11.2463C7.46336 11.4021 8.95753 13.6938 11.2309 14.6746C11.7717 14.9129 12.1934 15.0504 12.5234 15.1513C13.0642 15.3254 13.5592 15.2979 13.9534 15.2429C14.3934 15.1788 15.3009 14.6929 15.4842 14.1613C15.6767 13.6296 15.6767 13.1804 15.6125 13.0796C15.5484 12.9788 15.4109 12.9329 15.1817 12.8229Z"
        fill="#DCC5B7"
      />
    </svg>
  );
};
