import axios from "axios";
import { apiInstance } from "../base";
import { API_URL } from "shared/config";

export const getAll = () => {
  return axios({
    method: "get",
    url: API_URL,
  });
};
