import bg from "../../images/concept-bg.jpg";

import s from "./styles.module.sass";

export const ConceptSubMobile = () => {
  return (
    <section id="concept" className={`${s.section} container `}>
      <div className={s.content}>
        <h5>
          <span>Коктейльный бар</span>
          <span>с танцевальной музыкой</span>
          <span>в центре Сочи</span>
        </h5>
        <p>
          Место притяжения для самой изысканной публики, место стиля, место
          модной музыки от топовых диджеев.
        </p>
      </div>
      {/* <div className={styles.imageWrapper}>
          <img src={bg} alt="section-2" />
        </div> */}
    </section>
  );
};
