import { API_URL } from "shared/config";
import s from "./styles.module.sass";
import { useGetAll } from "shared/lib";

export const VideoMob = () => {
  const { resData } = useGetAll();
  return (
    <div className={s.videoWrapper}>
      {resData ? (
        <video playsInline autoPlay muted loop>
          <source
            src={`${API_URL}${resData["Видео для моб"]}`}
            type="video/mp4"
          />
        </video>
      ) : null}
    </div>
  );
};
